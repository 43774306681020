<!-- 选择居民弹窗-->
<template>
  <div>
    <el-dialog
      title="选择人员"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :z-index="12"
      :close-on-click-modal="false"
    >
      <!-- 左侧树 -->
      <div class="left">
        <!-- 左边竖线样式写法 -->
        <div
          style="
            display: flex;
            align-items: center;
            border-left: 4px solid #556bff;
            padding-left: 5px;
          "
        >
          <div class="title">网格列表</div>
        </div>
        <div style="margin-top: 3%; padding-right: 2%">
          <el-input
            placeholder="输入关键字进行过滤"
            prefix-icon="el-icon-search"
            v-model="filterText"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="data"
            @node-click="treeClick"
            :filter-node-method="filterNode"
            ref="tree"
          >
          </el-tree>
        </div>
      </div>
      <!-- 中间表格 -->
      <div class="centers">
        <ax-table
          ref="tableBox"
          :columns="columns"
          :show-search="true"
          :searchForm="searchForm"
          :isCard="false"
          :showToolBar="false"
          :dataSourceApi="api.list"
          :dataSourceParams="dataSourceParams"
           :scroll="{ y: '55vh', x: '80vw' }"
          :row-selection="{
            type: 'radio',
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
        </ax-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <a-button
          style="
            margin: 0 1%;
          "
          >取 消</a-button
        >
        <a-button type="primary" @click="setPeopleId">确 定</a-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "searchText",
    options: { placeholder: "输入部门或人员名称" },
    formItem: {},
    col: { span: 12 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: ["personInfo"],
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      // 左树
      filterText: "",
      data: [],
      treeID: "",
      // 中间表格
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 80,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 110,
          },
          {
            title: "现住区域",
            dataIndex: "currentArea",
            ellipsis: true,
            width: 180,
          },
        ],
        false
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 56,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
        { name: "add", text: "保存" },
      ],
      selectedRows: [],
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: {},
      // 右侧列表
      activeIndex: null,
      allRows: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.visible = true;
      this.getgridTree();
      this.$nextTick(() => {
        this.getTableListData();
      });
      if (this.personInfo) {
        this.selectedRows = this.personInfo;
        this.selectedRowKeys = this.personInfo.map((user) => user.id);
      }
    },
    // 左树操作区
    async getgridTree() {
      try {
        const res = await api.gridTree();
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            level: res.level,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        this.data = treeData;
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        level: child.level,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 树点击
    async treeClick(e) {
      // if (e.value) {
      //   this.dataSourceParams.gridId = e.value;
      //   this.$refs.roleTable.getDataSource();
      //   this.treeID = e.value;
      // }
      // if (e.label) {
      //   this.gridName = e.label;
      // }
      // if (e.level) {
      //   this.level = e.level;
      // }
    },
    // 过滤树节点函数
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 中间表格操作区
    async getTableListData() {
      const obj = {
        pageNo: 1,
        pageSize: 10,
      };
      this.$refs.tableBox.getDataSource(obj);
    },
    getCheckboxProps(record) {
      if (this.personInfo) {
        console.log(record.id, "121");
        const isChecked = this.personInfo.some((user) => user.id === record.id);
        return {
          checked: isChecked,
        };
      }
      return {};
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      // 更新 selectedRowKeys
      this.selectedRowKeys = selectedRowKeys;
      // 如果 this.allRows 不存在需要初始化
      if (!this.allRows) {
        this.allRows = [];
      }
      // 合并新选中的行到 allRows 中
      this.allRows.push(...selectedRows);
      // 去重处理 - 使用一个 Map 来确保唯一性
      const rowMap = new Map();
      this.allRows.forEach((row) => {
        rowMap.set(row.id, row); // 假设 row.id 是唯一标识符
      });
      // 将 Map 的值转换为数组并赋值给 this.allRows
      this.allRows = Array.from(rowMap.values());
      // 只保留在 selectedRowKeys 中的行
      this.selectedRows = this.allRows.filter((row) =>
        selectedRowKeys.includes(row.id)
      );
    },
    setPeopleId() {
      if (this.selectedRowKeys.length == "0") {
        this.visible = true;
        this.$message.warning("请选择人员");
      } else {
        this.visible = false;
        this.$emit("receive", this.selectedRows);
      }
    },

    // 右侧已选择操作区
    setActive(index) {
      console.log(index);
      if (index === this.activeIndex) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    // 删除对应人员
    del(item) {
      this.selectedRows = this.selectedRows.filter((i) => i.id !== item.id);
      this.selectedRowKeys = this.selectedRowKeys.filter(
        (key) => key !== item.id
      );
      console.log("已删除：", item);
      this.getTableListData();
      console.log(this.selectedRows);
    },
    // 清空已选择人员
    clearSelectedRows() {
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    // 关闭弹窗
    closeModal() {
      this.selectedRows = [];
      this.visible = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}
/deep/.el-dialog__body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // height: 650px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.left {
  width: 20%;
}
.centers {
  width: 75%;
}
/deep/.ant-card-head-title {
  background-color: #fff;
}
/deep/.ant-btn {
  background: #fff !important;
  border: 1px solid#d9dde2 !important;
  color: #000 !important;
}
/deep/.ant-btn-primary {
  background: #2a5cff !important;
  border: #2a5cff !important;
  color: #fff !important;
}
</style>